import "./product.css";

const Product = ({img,link,bla,stack}) => {
  return (
    <div className="pl-b">
        
        <div className="pl-c">
            <div className="p">
                <div className="p-browser">
                    <div className="p-circle"></div>
                    <div className="p-circle"></div>
                    <div className="p-circle"></div>
                </div>
            
                <a href={link} target="_blank" rel="noreferrer">
                    <img src={img} alt="" className="p-img" />
                </a>
                

            </div>
        
            
        </div>
        
        <div className="pl-c">{bla}</div>
        <div className="pl-c">{stack}</div>
        
    </div>
  );
};

export default Product;