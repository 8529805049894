import "./about.css";
import linkedin from "../../img/linkedin.png";
import github from "../../img/github.png";
import cvicon from "../../img/cv.png";
import cv from "../../img/timCV.pdf";
import aboutIcon from "../../img/about.png";

const About = () => {
  return (
    <div className="a">
      <div className="a-left">
        {/* <div className="a-card bg"></div> */}
        <div className="a-card">
            <img src={aboutIcon} alt="tim2" className="a-img" />
        </div>
      </div>
      <div className="a-right">
        <h1 className="a-title">About Me</h1>
        <p className="a-sub">
        Hello there, my name is Timotius Mogot, but you can simply call me Tim.  I am excited to share that I have graduated from Coder Academy's Software Engineering Immersive bootcamp in 2022 and am eager to bring my skills and experiences to new challenges and projects as a data engineer or junior software engineer.
        </p>
        <p className="a-sub">
       

        Throughout my bootcamp, I had the unique opportunity to gain hands-on experience with a wide range of technologies including HTML, CSS, JavaScript, Python, Ruby, Rails, PostgresQl, Reactjs, Express, Nodejs, MongoDB, and Github. In addition, my diverse background having studied in business, music, and the health sector, has emphasized my aptitude for learning different fields and has developed my flexibility and problem-solving capabilities.


        </p>
        <p className="a-sub">
        I also have working experience in the financial market as a proprietary trader and market analyst, specifically in both FX and cryptocurrency. I have been consistently engaged in these markets since 2008. My interests are focused on both technology and finance, such as software development, engineering, blockchain, AI and machine learning.
        </p>

        <p className="a-sub">My passion for building front-end and back-end applications using these programming languages and tools is like solving a puzzle, which helps improve my problem-solving skills. If you are seeking a highly motivated and skilled junior software engineer to join your team, I would be honored to have the opportunity to connect with you. Please don't hesitate to reach out and let's get in touch! 

        </p>
        
        <p className="a-sub">
          I am currently working as a data engineer.
        </p>
        
        
        
          <div>
              <a href="https://github.com/Sky12072"><img className="sosmed" src={github} alt="github icon" /></a>
              <a href="https://www.linkedin.com/in/timotius-mogot"><img className="sosmed" src={linkedin}
                      alt="linkedin icon" /></a>
              <a href={cv}><img className="sosmed" src={cvicon}
                      alt="instagram icon" /></a> 
          </div>
        
          
        
      </div>
    </div>
  );
};

export default About;