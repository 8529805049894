import "./skills.css";
import Reactpic from "../../img/skillsImg/react.png";
import Csspic from "../../img/skillsImg/css.png";
import Firebasepic from "../../img/skillsImg/firebase.png";
import Githubpic from "../../img/skillsImg/github1.png";
import Htmlpic from "../../img/skillsImg/html.png";
import Javascriptpic from "../../img/skillsImg/javascript.png";
import Mongopic from "../../img/skillsImg/mongo.png";
import Nodepic from "../../img/skillsImg/node.png";
import Expresspic from "../../img/skillsImg/expressjs.png";
import Railspic from "../../img/skillsImg/rails.png";
import Rubypic from "../../img/skillsImg/ruby.png";
import Pythonpic from "../../img/skillsImg/python.png";

const Skills = () => {
  

  return (
    <div className="mainTitle" >
        <h1 className="pl-title">Skills</h1>
        <div id="blog-mainbody">
        
        {/* <!-- Second container to manage six flexbox elements --> */}
        <div className="image-direction1">

            {/* <!-- First flexbox element --> */}
            <div className="thumbnail-text">
                <img className="image-size" src={Reactpic} alt="reactjs" />
                <div className="thumbnail-description">
                    <h3>React</h3>
                    
                   
                </div>
            </div>

            {/* <!-- Second flexbox element --> */}
            <div className="thumbnail-text">
                <img className="image-size" src={Nodepic} alt="nodejs" />
                <div className="thumbnail-description">
                    <h3>Node</h3>
                    
                </div>
            </div>

            {/* <!-- Third flexbox element --> */}
            <div className="thumbnail-text">
                <img className="image-size" src={Expresspic} alt="expressjs" />
                <div className="thumbnail-description">
                    <h3>Express</h3>
                    
                    
                </div>
            </div>

            {/* <!-- Fourth flexbox element --> */}
            <div className="thumbnail-text">
                <img className="image-size" src={Mongopic} alt="mongodb" />
                <div className="thumbnail-description">
                    <h3>MongoDB</h3>
                    
                </div>
            </div>

            {/* <!-- Fifth flexbox element --> */}
            <div className="thumbnail-text">
                <img className="image-size" src={Javascriptpic} alt="javascript" />
                <div className="thumbnail-description">
                    <h3>Javascript</h3>
                    
                </div>
            </div>

            {/* <!-- Sixth flexbox element --> */}
            <div className="thumbnail-text">
                <img className="image-size" src={Firebasepic} alt="firebase" />
                <div className="thumbnail-description">
                    <h3>Firebase</h3>
                    
                    
                </div>
            </div>

            {/* <!-- Seventh flexbox element --> */}
            <div className="thumbnail-text">
                <img className="image-size" src={Githubpic} alt="github" />
                <div className="thumbnail-description">
                    <h3>Github</h3>
                    
                </div>
            </div>

            {/* <!-- Eighth flexbox element --> */}
            <div className="thumbnail-text">
                <img className="image-size" src={Htmlpic} alt="html" />
                <div className="thumbnail-description">
                    <h3>HTML</h3>
                    
                    
                </div>
            </div>

            {/* <!-- Ninth flexbox element --> */}
            <div className="thumbnail-text">
                <img className="image-size" src={Csspic} alt="css" />
                <div className="thumbnail-description">
                    <h3>CSS</h3>
                    
                    
                </div>
            </div>

            {/* <!-- Tenth flexbox element --> */}
            <div className="thumbnail-text">
                <img className="image-size" src={Rubypic} alt="ruby" />
                <div className="thumbnail-description">
                    <h3>Ruby</h3>
                    
                    
                </div>
            </div>

            {/* <!-- Eleventh flexbox element --> */}
            <div className="thumbnail-text">
                <img className="image-size" src={Railspic} alt="rails"  />
                <div className="thumbnail-description">
                    <h3>Rails</h3>
                    
                    
                </div>
            </div>

            {/* <!-- Twelveth flexbox element --> */}
            <div className="thumbnail-text">
                <img className="image-size" src={Pythonpic} alt="python" />
                <div className="thumbnail-description">
                    <h3>Python</h3>
                    
                    
                </div>
            </div>
        </div>
        </div>

    </div>
  );
};

export default Skills;