import "./productList.css";
import Product from "../product/Product";
import { data } from "../../Data";


// console.log("Products in list is: ", data)

const ProductList = () => {
  return (
    <div className="pl">
      <div className="pl-texts">
        <h1 className="pl-title">Projects</h1>
        <p className="pl-desc">
          I love creating new projects. It's fun and keeps me up to date with the tech trend. 
        </p>
        {/* <p className="pl-desc">
          What I've Built
        </p> */}
      </div>

      <div className="pl-list">
        {/* <div className="pl-list2"> */}
          {data.map((item) => (
            <Product key={item.id} img={item.img} link={item.link} bla={item.bla} stack={item.stack}/>
          ))}
        {/* </div> */}
        
            
         
      </div>
      
    </div>
  );
};

export default ProductList;