import "./contact.css";
import { useContext, useState } from "react";
import emailjs from "emailjs-com";
import { ThemeContext } from "../../Context";
import linkedin from "../../img/linkedin.png";
import github from "../../img/github.png";
import cvicon from "../../img/cv.png";
import cv from "../../img/timCV.pdf";

const Contact = () => {
  
  const [done, setDone] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false);

  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  const initialFormState = {
		name: '', 
		subject: '',
    email: '',
    message: ''
	}


  const [formstate, setFormState] = useState(initialFormState)
  

  const handleChange = (event) => {
    setFormState({
      ...formstate,
      [event.target.name]: event.target.value
      
    })
    
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formstate.name || !formstate.subject || !formstate.email || !formstate.message ) {
      setErrorMessage(true);
      setDone(false)
    }
    else {
      emailjs.send('service_aiu47io', 'template_mgzim2d', formstate, 'kIWVipaouxRdGlSjE')
      .then(result => {
        console.log('SUCCESS!', result.status, result.text);
        setErrorMessage(false);
        setDone(true)
      }, (error) => {
        console.log('FAILED...', error.text);
      });
    }
    
    
  };
  
 

  

  return (
    <div className="c">
      <div className="c-bg"></div>
      <div className="c-wrapper">
        <div className="c-left">
          <h1 className="c-title">Get In Touch</h1>
          <div className="c-info">
            {/* <div className="c-info-item">
              <img src={Phone} alt="" className="c-icon" />
              asdf
            </div> */}
            {/* <div className="c-info-item">
              <img className="c-icon" src={Email} alt="" />
              contact@sdfg.com
            </div> */}
            {/* <div className="c-info-item">
              <img className="c-icon" src={Address} alt="" />
              245 King Street,
            </div> */}
          </div>
        </div>
        <div className="c-right">
          
          <form  id="myForm" onSubmit={handleSubmit}>
            <input style={{backgroundColor: darkMode && "#f0eded"}} type="text" placeholder="Name" name="name" onChange={handleChange}/>
            <input style={{backgroundColor: darkMode && "#f0eded"}} type="text" placeholder="Subject" name="subject" onChange={handleChange}/>
            <input style={{backgroundColor: darkMode && "#f0eded"}} type="email" placeholder="Email" name="email" onChange={handleChange}/>
            <textarea style={{backgroundColor: darkMode && "#f0eded"}} rows="5" placeholder="Message" name="message" onChange={handleChange}/>
            <button>Submit</button>
            <div>
            {errorMessage ? errorMessage && <div style={{ color: 'red' }}>All fields can not be empty.</div> : done && <div> Thank you, your message has been sent! </div>}
            </div>
            
            
            
          </form>
          
        </div>
        


      </div>


      <footer>
        
        {/* <!-- Clickable social media icons --> */}
        <figure>
            <a href="https://github.com/Sky12072"><img className="sosmed" src={github} alt="github icon" /></a>
            <a href="https://www.linkedin.com/in/timotius-mogot"><img className="sosmed" src={linkedin}
                    alt="linkedin icon" /></a>
            <a href={cv}><img className="sosmed" src={cvicon}
                    alt="instagram icon" /></a> 
        </figure>
    </footer>
    <script type="text/javascript" src="./ValidateForm.js"></script>
    </div>
  );
  
};

export default Contact;