import pokeEdu from "./img/pokeEdu.png";
import marketplace from "./img/marketplace.png";
import terminal from "./img/terminal.gif";
import employee from "./img/employer.png";
import employer from "./img/employee.png";


export const data = [
    {
      id: 1,
      img: pokeEdu,
      link: "https://poke-edu-jigglypuffteam-hackathon.netlify.app/",
      bla: "Hackathon",
      stack: "Javascript, HTML, CSS"
    },
    {
      id: 2,
      img: marketplace,
      link: "http://marketplace-app-tim.herokuapp.com/",
      bla: "2-sided Marketplace",
      stack: "Ruby on Rails"
    },
    {
      id: 3,
      img: terminal,
      link: "https://github.com/Sky12072/terminal-assessment",
      bla: "Terminal app",
      stack: "Ruby"
    },
    {
      id: 4,
      img: employer,
      link: "https://rosterportal-frontend-fullstack.netlify.app",
      bla: "Full Stack - Frontend Employee (Desktop)",
      stack: "Reactjs Nodejs Expressjs MongoDB"
    },
    {
      id: 5,
      img: employee,
      link: "https://rosterportal-frontend-fullstack.netlify.app",
      bla: "Full Stack - Frontend Employer (Desktop)",
      stack: "Reactjs Nodejs Expressjs MongoDB"
    },
    // {
    //   id: 6,
    //   img: "",
    //   link: "http://lama.dev",
    // },
  ];